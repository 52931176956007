<template>
  <section>
    <AuthorFilter />
    <Preloader v-if="callingAPI" />
    <AuthorTable />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import AuthorFilter from '@/components/filter/FilterAuthorVlm'
import AuthorTable from '@/components/table/AuthorTableVlm'

export default {
  name: 'AuthorListViewVlm',
  components: {
    AuthorTable,
    Preloader,
    AuthorFilter
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  }
}
</script>
