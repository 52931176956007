<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="authors"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    :button-required-permissions="requiredPermissions"
    store-name="author"
    table-css-class="author-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="siteTitleById(item.defaultSite)"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.name"
      />
      <TitleColumn
        :title-header="tableHeaders[3].name"
        :title-value="item.originName"
      />
      <TitleColumn
        :title-header="tableHeaders[4].name"
        :title-value="item.customText"
      />
      <TitleColumn
        :title-header="tableHeaders[6].name"
        :title-value="getUser(item)"
      />
      <CheckColumn
        :header="tableHeaders[6].name"
        :check="Boolean(item.hiddenOnPage)"
      />
      <CheckColumn
        :header="tableHeaders[7].name"
        :check="Boolean(item.disabled)"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdBy"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CheckColumn from '@/components/table/columns/CheckColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'
import PermissionService from '@/services/PermissionService'
import SiteService from '@/services/site/SiteService'
import UserService from '@/services/user/UserService'

export default {
  name: 'AuthorTableVlm',
  components: {
    CheckColumn,
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  computed: {
    tableHeaders () {
      return [
        { name: 'Id' },
        { name: this.$t('authorVlm.defaultSite') },
        { name: this.$t('authorVlm.firstName') },
        { name: this.$t('authorVlm.lastName') },
        { name: this.$t('authorVlm.customText') },
        { name: this.$t('authorVlm.user') },
        { name: this.$t('authorVlm.hiddenOnPage_short') },
        { name: this.$t('authorVlm.disabled') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        { name: this.$t('actions') }
      ]
    },
    authors () {
      return this.$store.getters['author/list']
    },
    totalCount () {
      return this.$store.getters['author/totalCount']
    },
    currentPage () {
      return this.$store.getters['author/page']
    }
  },
  data () {
    return {
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_AUTHOR_PERMISSIONS
    }
  },
  methods: {
    getAuthors () {
      this.$store.dispatch('author/fetch')
    },
    getUser (item) {
      const user = this.$store.getters['user/userById'](item.user)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return item.user
    },
    onPageChange (page) {
      this.$store.commit('author/setPage', page)
      this.getAuthors()
    },
    siteTitleById (id) {
      return SiteService.getSiteTitle(id)
    }
  },
  created () {
    this.getAuthors()
  }
}
</script>
<style lang="scss">
  .author-table {
    &__thead,
    &__tr {
      grid-template-columns: rem(80px) repeat(auto-fit, minmax(100px, 1fr));
    }
  }
</style>
